import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
	name: 'chat/state',
	initialState: {
		drawerOpen: false,
		drawerOpen2: false,
		sortedColumn: () => { },
	},

	reducers: {

		setSortedColumn: (state, action) => {
			state.sortedColumn = action.payload
		},
		setDrawerOpen: (state) => {
			state.drawerOpen = true
		},
		setDrawerClose: (state) => {
			state.drawerOpen = false
		},
		setDrawerOpen2: (state) => {
			state.drawerOpen2 = true
		},
		setDrawerClose2: (state) => {
			state.drawerOpen2 = false
		}
	},
})

export const {
	setDrawerOpen2,
	setDrawerClose2,
	setDrawerOpen,
	setDrawerClose,
	setSortedColumn
} = stateSlice.actions

export default stateSlice.reducer