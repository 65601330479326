import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash';

import { apiCustomRepdate, apiContactMsgs, apiCustomRep, apiAllStatictec, apiSentEndMsg, apiEndChat, apiGetMessagesData, apiSentTempMsg, apiGetContactsData, apiGetNewMessagesData, apiPutContactsData, apiGetNewContactsData, apiPostMessageData, apiGetContactsDataUpdate, apiSentTempMsgList, apiGetTempMsgList } from 'services/chat'

export const getCustomRep = createAsyncThunk('chat/data/getCustomRep', async (id, { rejectWithValue }) => {

    try {
        const response = await apiCustomRep(id)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})


export const getCustomRep_Date = createAsyncThunk('chat/data/getCustomRep_Date', async (date_FT, { rejectWithValue }) => {

    try {
        console.log(date_FT)
        const response = await apiCustomRepdate(date_FT.fromDate, date_FT.toDate)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})

export const getContactMsgs = createAsyncThunk('chat/data/getContactMsgs', async (id, { rejectWithValue }) => {

    try {
        const response = await apiContactMsgs(id)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})

export const getAllStatictec = createAsyncThunk('chat/data/getAllStatictec', async (id, { rejectWithValue }) => {

    try {
        const response = await apiAllStatictec()

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})

export const sendTempMsgList = createAsyncThunk('chat/data/sendTempMsgList', async (mob, { rejectWithValue }) => {

    try {
        ////console.log(mob)
        const response = await apiSentTempMsgList(mob)

        return response.data
    } catch (err) {
        //console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})



export const sendTempMsg = createAsyncThunk('chat/data/sendTempMsg', async (mob, { rejectWithValue }) => {

    try {
        const response = await apiSentTempMsg(mob)

        return response.data
    } catch (err) {
        ////console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})

export const sendEndMsg = createAsyncThunk('chat/data/sendEndMsg', async (mob, { rejectWithValue }) => {

    try {
        const response = await apiSentEndMsg(mob)

        return response.data
    } catch (err) {
        ////console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})


export const getMessagesTempleteList = createAsyncThunk('chat/data/getMessagesTempleteList', async (data, { rejectWithValue }) => {

    try {
        const response = await apiGetTempMsgList()

        return response.data
    } catch (err) {
        ////console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})


export const getMessagesData = createAsyncThunk('chat/data/getMessagesData', async (data, { rejectWithValue }) => {

    try {
        const response = await apiGetMessagesData()

        return response.data
    } catch (err) {
        ////console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }
})


export const getContactsData = createAsyncThunk('chat/data/getContactsData', async (data, { rejectWithValue }) => {
    try {
        const response = await apiGetContactsData()

        return response.data
    } catch (err) {
        ////console.log(err)
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }


})

export const getNewMessagesData = createAsyncThunk('chat/data/getNewMessagesData', async (id, { rejectWithValue }) => {
    try {
        const response = await apiGetNewMessagesData(id)


        return response.data
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }


})
export const getNewContactsData = createAsyncThunk('chat/data/getNewContactsData', async ({ rejectWithValue }) => {
    try {
        //  const response = await apiGetNewContactsData(id)
        const response = await apiGetContactsData()

        return response.data
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }

})


export const getContactsDataUpdate = createAsyncThunk('chat/data/getContactsDataUpdate', async (id, { rejectWithValue }) => {
    try {
        const response = await apiGetContactsDataUpdate(id)
        return response.data
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }

})

export const PutContactsData = createAsyncThunk('chat/data/PutContactsData', async (id, { rejectWithValue }) => {
    try {
        const response = await apiPutContactsData(id)
        return response.data
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }


})

export const EndChat = createAsyncThunk('chat/data/EndChat', async (id, { rejectWithValue }) => {
    try {
        const response = await apiEndChat(id)
        return response.data
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }


})

export const postMessageData = createAsyncThunk('chat/data/postMessageData', async (data, { rejectWithValue }) => {
    try {
        const response = await apiPostMessageData(data)
        return response.data
    } catch (err) {
        // Use `err.response.data` as `action.payload` for a `rejected` action,
        // by explicitly returning it using the `rejectWithValue()` utility
        return rejectWithValue(err.response.data)
    }


})

export const initialFilterData = {
    status: '',
}

export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    sort: {
        order: '',
        key: ''
    }
}

const dataSlice = createSlice({
    name: 'chat/data',
    initialState: {
        loading: true,
        messageList: null,
        contactList: null,
        messageListNew: null,
        contactListNew: null,
        PaginationContacts: [],
        alldata: null,
        table_rep: [],
        Contact_Msgs: [],
        templeteMessageList: null,
        filterChat: 1,
        selected: {
            contact: {},
            messages: []
        },
        tableData: initialTableData,

    },
    reducers: {
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterChat: (state, action) => {
            state.filterChat = action.payload

        },
        setTableData2: (state, action) => {
            state.tableData.total = action.payload.total

            state.PaginationContacts = action.payload.data
        },
        setSelected: (state, action) => {

            state.selected.contact = action.payload.contact
            const msgs = state.messageList ? state.messageList.filter((msg) => msg.contacts === action.payload.contact.id) : []
            state.selected.messages = _.orderBy(msgs, ['id'], ['asc']);
        },
        setEndChat: (state, action) => {

            state.selected.contact = {}

            state.selected.messages = []
            if (!
                action.payload.checked) {
                state.contactList = state.contactList.filter((cont) => cont.id !== action.payload.id)

            }
        },

        setEmptySelected: (state, action) => {

            state.selected.contact = {}

            state.selected.messages = []
        },
        UpdateContacts: (state, action) => {
            const getdataupdate = _.orderBy(action.payload, ['lastupdate'], ['desc']);
            const currentdataupdate = _.orderBy(state.contactList, ['lastupdate'], ['desc']);

            if (currentdataupdate.length > 0 && currentdataupdate[0].lastupdate >= getdataupdate[0].lastupdate) {
                return;
            }

            state.contactList = getdataupdate;
        },
        AddNewMessage: (state, action) => {
            const newObjects = action.payload;
            const currentState = state.messageList;
            if (newObjects && currentState) {
                const newState = _.uniqBy([...currentState, ...newObjects], 'id');
                // //console.log(newState)
                //return { ...state, contactList: newState }
                state.messageList = newState
                if (state.selected.messages && newState) {
                    const msgs = newState.filter((msg) => msg.contacts === state.selected.contact.id)
                    state.selected.messages = _.orderBy(msgs, ['id'], ['asc']);
                }
            }


        },

    },
    extraReducers: (builder) => {
        builder
            //getContactMsgs

            .addCase(getContactMsgs.fulfilled, (state, action) => {

                state.Contact_Msgs = _.orderBy(action.payload, ['id'], ['asc']);
                state.error = ""
                state.loading = false

            })
            .addCase(getContactMsgs.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getContactMsgs.rejected, (state, action) => {
                state.loading = false
            })
//
            .addCase(getCustomRep_Date.fulfilled, (state, action) => {

                state.table_rep = action.payload
                state.error = ""
                state.loading = false

            })
            .addCase(getCustomRep_Date.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getCustomRep_Date.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(getCustomRep.fulfilled, (state, action) => {

                state.table_rep = action.payload
                state.error = ""
                state.loading = false

            })
            .addCase(getCustomRep.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getCustomRep.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(getAllStatictec.fulfilled, (state, action) => {

                state.alldata = action.payload
                state.error = ""
                state.loading = false

            })
            // .addCase(getAllStatictec.pending, (state, action) => {
            //     state.loading = true
            // })
            // .addCase(getAllStatictec.rejected, (state, action) => {
            //     state.loading = false
            // })
            .addCase(EndChat.fulfilled, (state, action) => {
                if (action.payload) {
                    state.messageList = state.messageList.map((msg) => msg.id === action.payload.id ? action.payload : msg)
                }
            })
            .addCase(sendEndMsg.fulfilled, (state, action) => {
                //sendEndMsg   state.messageList = _.orderBy(action.payload, ['id'], ['desc']);
                if (action.payload) {

                    const newObjects = action.payload;
                    const currentState = state.messageList;
                    const newState = _.uniqBy([...currentState, ...newObjects], 'id');
                    //return { ...state, contactList: newState }


                    state.messageList = newState

                    if (state.selected.messages && newState) {
                        const msgs = newState.filter((msg) => msg.contacts === state.selected.contact.id)
                        state.selected.messages = _.orderBy(msgs, ['id'], ['asc']);
                    }

                }
                state.loading = false
            })
            .addCase(postMessageData.fulfilled, (state, action) => {
                //sendEndMsg   state.messageList = _.orderBy(action.payload, ['id'], ['desc']);
                if (action.payload) {

                    const newObjects = action.payload;
                    const currentState = state.messageList;
                    const newState = _.uniqBy([...currentState, ...newObjects], 'id');
                    //return { ...state, contactList: newState }


                    state.messageList = newState

                    if (state.selected.messages && newState) {
                        const msgs = newState.filter((msg) => msg.contacts === state.selected.contact.id)
                        state.selected.messages = _.orderBy(msgs, ['id'], ['asc']);
                    }

                }
                state.loading = false
            })
            .addCase(postMessageData.pending, (state, action) => {
                state.loading = false
            })
            .addCase(getMessagesTempleteList.fulfilled, (state, action) => {
                state.templeteMessageList = action.payload
            })
            .addCase(getMessagesData.fulfilled, (state, action) => {
                state.messageList = _.orderBy(action.payload, ['id'], ['desc']);
                state.loading = false
            })
            // .addCase(getMessagesData.pending, (state, action) => {
            //     state.loading = true
            // })
            // .addCase(getMessagesData.rejected, (state, action) => {
            //     state.loading = false
            // })

            .addCase(getContactsData.fulfilled, (state, action) => {

                state.contactList = _.orderBy(action.payload, ['lastupdate'], ['desc']);


            })
            // .addCase(getContactsData.pending, (state, action) => {
            //     state.loading = false
            // })
            // .addCase(getContactsData.rejected, (state, action) => {
            //     state.loading = true
            // })

            .addCase(getNewContactsData.fulfilled, (state, action) => {
                const newObjects = _.orderBy(action.payload, ['lastupdate'], ['desc']);
                state.contactList = newObjects
                state.contactListNew = _.take(newObjects, 25)

                // if (action.payload){
                //     const newObjects = action.payload;
                //     const currentState = state.contactList;
                //     const newState = _.uniqBy([...currentState, ...newObjects], 'id');
                //     //return { ...state, contactList: newState }
                //     state.contactList = newState

                // }

            })
            //
            .addCase(getContactsDataUpdate.fulfilled, (state, action) => {
                state.selected.contact = action.payload
            })
            // .addCase(getNewContactsData.pending, (state, action) => {
            //     state.loading = true
            // })
            // .addCase(getNewContactsData.rejected, (state, action) => {
            //     state.loading = true
            // })

            .addCase(getNewMessagesData.fulfilled, (state, action) => {

                if (action.payload.length > 0) {

                    const newObjects = action.payload;
                    const currentState = state.messageList;
                    const newState = _.uniqBy([...currentState, ...newObjects], 'id');
                    //return { ...state, contactList: newState }


                    state.messageList = newState
                    state.messageListNew = _.take(_.orderBy(action.payload, ['id'], ['desc']), 1000);

                    if (state.selected.messages && newState) {
                        const msgs = newState.filter((msg) => msg.contacts === state.selected.contact.id)
                        state.selected.messages = _.orderBy(msgs, ['id'], ['asc']);
                    }

                }


            })
        // .addCase(getNewMessagesData.pending, (state, action) => {
        //     state.loading = true
        // })
        // .addCase(getNewMessagesData.rejected, (state, action) => {
        //     state.loading = true
        // })

    },
})
export const {
    setSelected,
    AddNewMessage,
    UpdateContacts,
    setTableData,
    setTableData2,
    setEmptySelected,
    setFilterChat,
    setEndChat,
} = dataSlice.actions
export default dataSlice.reducer
